import { readMoreButton } from "./util";

const BeneftisComponent = (() => {
    return {
        init() {
            if(window.innerWidth < 1025) {
                readMoreButton(
                    document.querySelector('.js-benefits-section-content'),
                    document.querySelectorAll('.js-benefits-item'),
                    document.getElementById('viewMoreBenefits'),
                    document.getElementById('viewLessBenefits'),
                    'benefits-section__item-btn',
                    2,
                    true
                );
            }
        }
    }
})();

export default (() => {
    $(document).ready(BeneftisComponent.init);
})();
